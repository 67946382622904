jQuery(document).ready(function($){

    /* SLIDERS */

    $('.slider-fade').slick({
        touchThreshold:100,
        autoplay: true,
        autoplaySpeed: 3500
    });

    /* BOUTON RETOUR TOP */

    $(window).scroll(function()
    {
        var scroll = $(window).scrollTop();
        if (scroll > 10 ) {
            $(".retour-top").css("display", "block");
        } else {
            $(".retour-top").css("display", "none");
        }
    });

    $(".retour-top").click(function(e) {
        $('html,body').animate({scrollTop: $('.site').offset().top},'slow');
    });

    /* DEFILEMENT */

    $(".sliding-link").click(function(e) {

        e.preventDefault();
        var aid = $(this).attr("href");
        var scroll = $(window).scrollTop();

        // Si le visiteur est placé en haut de l'écran ou pas on recale la hauteur a ajuster apres le glissement
        if (scroll > 10 ) {
            var marginTop = 100;
        }

        if (scroll <= 1 ) {
            var marginTop = 270;
        }

        var currentUrl = $(location).attr('pathname');
        var hostUrl = $(location).attr('host');

        // Si on est sur la page d'accueil on envoi vers l'ancre, sinon on redirige
        if (currentUrl === "/") {
            $('html,body').animate({scrollTop: $(aid).offset().top - marginTop},'slow');
        } else {
            $(location).attr('href', 'https://'+hostUrl+aid);
        }

    });

    /* REPLIAGE DU MENU BURGER */

    $(function () {
        $('.navbar-collapse ul li a:not(.dropdown-toggle)').click(function () {
            $('.navbar-toggle:visible').click();
        });
    });

    /* FORMULAIRE CONTACT */

    $( ".contact-submit" ).prop( "disabled", true );
    $( ".contact-submit" ).css( "opacity", '0.6' );

    $('#frm-contact').submit(function(event){
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(data) {
                $('#form-send').css('display','flex');
                $('#form-error').css('display','none');
                $('.contact-submit').attr("disabled","disabled");
            },
            error: function(error) {
                $('#form-error').css('display','flex');
                $('#form-send').css('display','none');
                $('#form-error').append(error);
            }
        });
    });

    /* CASE A COCHER RGPD */

    $(".checkbox-rgpd").click(function() {
        if ($('.checkbox-rgpd').is(':checked')) {
            $( ".contact-submit" ).prop( "disabled", false );
            $( ".contact-submit" ).css( "opacity", '1' );
        } else {
            $( ".contact-submit" ).prop( "disabled", true );
            $( ".contact-submit" ).css( "opacity", '0.6' );
        }
    });

});


/* MAP */

if ($('#carte').length) {

    var mymap = L.map('carte').setView([latitude, longitude], 12);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 16,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: mapboxkey
    }).addTo(mymap);

    var marker = L.marker([latitude, longitude]).addTo(mymap);

}

/* Effet du header en fixed, diminution de la hauteur au scroll */

jQuery(window).scroll(function(){

    var scroll = jQuery(window).scrollTop();

    if (window.matchMedia("(min-width: 768px)").matches) {
        if (scroll > 10 ) {
            jQuery('.navbar').addClass('scrolled');
            jQuery('#main-nav').addClass('little-padding');
            jQuery('#slider').css("margin-top", "13rem");
        }
        if (scroll <= 1 ) {
            jQuery('.navbar').removeClass('scrolled');
            jQuery('#main-nav').removeClass('little-padding');
            jQuery('#slider').css("margin-top", "27.7rem");
        }
    } else {
        jQuery('.navbar').removeClass('scrolled');
        jQuery('#main-nav').removeClass('little-padding');
        jQuery('#slider').css("margin-top", "13rem");
    }
});

function respMarginScrolled(x) {
    if (x.matches) {
        jQuery('.navbar').removeClass('scrolled');
        jQuery('#main-nav').removeClass('little-padding');
        jQuery('#slider').css("margin-top", "13rem");
    }
}

var x = window.matchMedia("(max-width: 768px)")
respMarginScrolled(x) // Call listener function at run time
x.addListener(respMarginScrolled) // Attach listener function on state changes

// Gestion des Cookies - Tarteaucitron

tarteaucitron.init({
    "privacyUrl": "", /* Privacy policy url */
    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "bottom", /* Banner position (top - bottom - middle - popup) */
    "groupServices": false, /* Group services by category */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "showIcon": false, /* Show cookie icon to manage cookies */
    "iconPosition": "BottomRight", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta" : true, /* Show the deny all button */
    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": false, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
    "readmoreLink": "/mentions-legales", /* Change the default readmore link pointing to tarteaucitron.io */
    "mandatory": true /* Show a message about mandatory cookies */
});

tarteaucitron.user.gtagUa = gtagGoogle;
tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
(tarteaucitron.job = tarteaucitron.job || []).push('gtag');
